import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const api_url = process.env.REACT_APP_API_URL;

const Popup = ({
  isOpen,
  toggle,
  data: rowData,
  onClose,
  productNameValue,
  quantityValue,
  dealTypeValue,
  scriptValue,
  positionValue,
  price1Value,
  targetValue,
  stoplossValue,
  newStatusValue,
  descriptionValue,
  adminName,
  customizeValue,
}) => {
  // console.log('✌️adminName --->', adminName);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    productName: "",
    quantity: "",
    dealType: "",
    script: "",
    position: "",
    price1: "",
    price2: "",
    price3: "",
    target: "",
    stopLoss: "",
    description: "",
    statusValue: "",
    customizeValue: "",
  });
  // console.log(rowData);

  useEffect(() => {
    // console.log(status);
  }, [status]);

  const HandleStatus = (e) => {
    const value = e.target.value;
    setStatus(value);
    // console.log(value);
  };

  const [updatedByAdminName, setAdminName] = useState(() =>
    sessionStorage.getItem("displayName")
  );

  const handlePopupConfirm = async () => {
    try {
      setSaveButtonDisabled(true);
      toggle();
      //  data submission logic here
      const data = new FormData(document.querySelector("#productForm"));

      const payload = {
        productName: data.get("productName"),
        quantity: data.get("quantity"),
        dealType: data.get("dealType"),
        script: data.get("script"),
        position: data.get("position"),
        price1: data.get("price1"),
        price2: data.get("price2"),
        price3: data.get("price3"),
        target: data.get("target"),
        stopLoss: data.get("stopLoss"),
        description: data.get("description"),
        statusValue: data.get("statusValue"),
        customizeValue: data.get("customizeValue"),
        // uploadFile:data.get('uploadFile'),
        created_by: adminName,
        updated_by: updatedByAdminName,
      };
      let pnl;

      // if (payload.statusValue === "Target HIT") {
      //   pnl = (payload.target - payload.price1) * payload.quantity;
      // } else if (payload.statusValue === "Stop Loss") {
      //   pnl = (payload.stopLoss - payload.price1) * payload.quantity;
      // } else if (payload.statusValue === "Exit" | payload.statusValue === "Exit at Cost" | payload.statusValue === "Book Profit" | payload.statusValue === "Book Loss" ) {
      //   pnl = (payload.customizeValue - payload.price1) * payload.quantity;
      // } else {
      //   pnl = 0; 
      // }

      if (payload.statusValue === "Target HIT") {
        if (payload.dealType === "Buy") {
          pnl = (payload.target - payload.price1) * payload.quantity;
        } else {
          pnl = (payload.price1 - payload.target) * payload.quantity;
        }
      } else if (payload.statusValue === "Stop Loss") {
        if (payload.dealType === "Buy") {
          pnl = (payload.stopLoss - payload.price1) * payload.quantity;
        } else {
          pnl = (payload.price1 - payload.stopLoss) * payload.quantity;
        }
      } else if (   payload.statusValue === "Exit" ||
      payload.statusValue === "Exit at Cost" ||
      payload.statusValue === "Book Profit" ||
      // payload.statusValue === "Revise Target" ||
      // payload.statusValue === "Revise Stop Loss" ||
      payload.statusValue === "Book Loss") {
        if (payload.dealType === "Buy") {
          pnl = (payload.customizeValue - payload.price1) * payload.quantity;
        } else {
          pnl = (payload.price1 - payload.customizeValue) * payload.quantity;
        }
        // pnl = (payload.customizeValue - payload.price1) * payload.quantity;
      } else {
        pnl = 0;
      }

      payload.pnl = pnl.toFixed(2);

      // console.log("payload", payload);
      let url = `${api_url}/sendcallFromAction`;

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData) {
        setFormData({
          productName: "",
          quantity: "",
          dealType: "",
          script: "",
          position: "",
          price1: "",
          price2: "",
          price3: "",
          target: "",
          stopLoss: "",
          description: "",
          statusValue: "",
          customizeValue: "",
        });
        setSaveButtonDisabled(false);
        navigate("/livecall");
      }
    } catch (error) {
      console.error("Error", error);
      setSaveButtonDisabled(false);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Edit Data</ModalHeader>
      <ModalBody>
        <div>
          <Form id="productForm" encType="multipart/form-data">
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Product</Label>
                  <Input
                    id="exampleSelect"
                    name="productName"
                    type="text"
                    required="true"
                    defaultValue={productNameValue}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword">Quantity</Label>
                  <Input
                    placeholder=" Enter Quantity"
                    type="number"
                    name="quantity"
                    required="true"
                    defaultValue={quantityValue}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleCity">Buy/Sell</Label>
                  <Input
                    id="exampleSelect"
                    name="dealType"
                    type="select"
                    defaultValue={dealTypeValue}
                  >
                    <option>--Select--</option>
                    <option>Buy</option>
                    <option>Sell</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleState">Script</Label>
                  <Input
                    id="exampleState"
                    name="script"
                    defaultValue={scriptValue}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleZip">Above/Below/At</Label>
                  <Input
                    id="exampleSelect"
                    name="position"
                    type="text"
                    defaultValue={positionValue}
                  >
                    <option>--Select--</option>
                    <option>Above</option>
                    <option>Below</option>
                    <option>At</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleCity">Entry Price</Label>
                  <Input
                    id="exampleSelect"
                    name="price1"
                    type="number"
                    min={0}
                    defaultValue={price1Value}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleState">With Target</Label>
                  <Input
                    id="exampleState"
                    name="target"
                    type="number"
                    defaultValue={targetValue}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="exampleZip">With Stop Loss</Label>
                  <Input
                    id="exampleSelect"
                    name="stopLoss"
                    type="number"
                    defaultValue={stoplossValue}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    id="exampleSelect"
                    name="statusValue"
                    type="select"
                    required="true"
                    placeholder="please select the Field"
                    onChange={HandleStatus}
                  >
                    <option selected disabled defaultValue="">
                      --Select--
                    </option>
                    <option>Target HIT</option>
                    <option>Stop Loss</option>
                    <option>Executed</option>
                    {/* <option>Exit</option> */}
                    <option>Exit at Cost</option>
                    <option>Book Profit</option>
                    <option>Book Loss</option>
                    <option>Revise Target</option>
                    <option>Revise Stop Loss</option>
                    {(newStatusValue === "Executed") |
                    (newStatusValue === "Stop Loss") |
                    (newStatusValue === "Exit") |  (newStatusValue === "Exit at Cost") |  (newStatusValue === "Book Profit") |  (newStatusValue === "Book Loss") | (newStatusValue === "Revise Target") | (newStatusValue === "Revise Stop Loss") |
                    (newStatusValue === "Target HIT") ? (
                      ""
                    ) : (
                      <>
                        <option>Avoid</option>
                      </>
                    )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="exampleCity">Description</Label>
                  <textarea
                    class="form-control"
                    // placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    rows={2}
                    name="description"
                    defaultValue={descriptionValue}
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* <Col md={4}>
                    <FormGroup>
                      <Label for="exampleZip">
                        Customize Value
                      </Label>
                      <Input
                        type="text"
                        defaultValue={newStatusValue}
                      >
                      </Input>
                    </FormGroup>
                  </Col> */}
              {status === "Exit at Cost" ? ( 
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleZip">Exit Price</Label>
                    <Input type="Number" name="customizeValue"></Input>
                  </FormGroup>
                </Col>
              ) : null}
              {status === "Book Profit" ? ( 
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleZip">Book Profit Price</Label>
                    <Input type="Number" name="customizeValue"></Input>
                  </FormGroup>
                </Col>
              ) : null}
              {status === "Book Loss" ? ( 
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleZip">Book Loss Price</Label>
                    <Input type="Number" name="customizeValue"></Input>
                  </FormGroup>
                </Col>
              ) : null}
              {status === "Revise Target" ? ( 
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleZip">Revise Target</Label>
                    <Input type="Number" name="customizeValue"></Input>
                  </FormGroup>
                </Col>
              ) : null}
              {status === "Revise Stop Loss" ? ( 
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleZip">Revise Stop Loss</Label>
                    <Input type="Number" name="customizeValue"></Input>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </Form>
        </div>
      </ModalBody>
      <ModalFooter>
        {newStatusValue === "Target HIT" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        ) : newStatusValue === "Exit" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        )  : newStatusValue === "Book Profit" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        )  : newStatusValue === "Book Loss" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        ): newStatusValue === "Revise Target" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        ) : newStatusValue === "Revise Stop Loss" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        )   : newStatusValue === "Exit at Cost" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        )   : newStatusValue === "Stop Loss" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        ) : newStatusValue === "Executed" ? (
          <p>
            <button
              className="btn btn-success"
              onClick={handlePopupConfirm}
              disabled={saveButtonDisabled}
            >
              Save
            </button>
          </p>
        ) : newStatusValue === "Avoid" ? (
          <p></p>
        ) : (
          <>
            {status === "Exit" | status === "Book Loss" | status === "Book Profit" | status === "Exit at Cost"  | status === "Revise Target"   | status === "Revise Stop Loss"   ? ( 
              <>
                <button
                  className="btn btn-success"
                  onClick={handlePopupConfirm}
                  disabled={saveButtonDisabled}
                >
                  Save
                </button>
              </>
            ) : (
              <button
                className="btn btn-success"
                onClick={handlePopupConfirm}
                disabled={saveButtonDisabled}
              >
                Send
              </button>
            )}
          </>
        )}
        <button className="btn btn-danger" onClick={onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default Popup;
