import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Home from "../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ImgPopUp from "./ImgPopUp";
import Popup from "./Popup";
const api_url = process.env.REACT_APP_API_URL;
//nested data is ok, see accessorKeys in ColumnDef below

const getStatusValueColor = (statusValue, pnl) => {
  if (statusValue == "Target HIT" | statusValue == "Book Profit" ) {
    return "green";
  } else if (statusValue == "Stop Loss" | statusValue == "Book Loss") {
    return "red";
  } else if (statusValue == "Exit at Cost" | statusValue == "Exit" ) {
    return "#FFB200";
  } else {
    return "black";
  }
};

const LiveCall = () => {
  const [callsData, setCallsData] = useState([]);
  // console.log('✌️callsData --->', callsData);

  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [imgPopupOpen, setImgPopupOpen] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedImg, setSelectedImg] = useState({});

  const [SelectedProductName, setSelectedProductName] = useState({});
  const [selectedQty, setSelectedQty] = useState({});
  const [selectedDealtype, setSelectedDealtype] = useState({});
  const [selectedScript, setSelectedScript] = useState({});
  const [selectedPosition, setSelectedPosition] = useState({});
  const [selectedPrice1, setSelectedPrice1] = useState({});
  const [selectedTarget, setSelectedTarget] = useState({});
  const [selectedStoploss, setSelectedStoploss] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedDescription, SetSelectedDescription] = useState({});
  const [selectedCreatedBy, SetSelectedCreatedBy] = useState({});

  const togglePopup = () => setPopupOpen(!isPopupOpen);
  const imgtogglePopup = () => {
    // console.log("ImgPopUp toggled");
    setImgPopupOpen(!imgPopupOpen);
  };

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleEditClick = (rowData) => {
    // console.log(rowData);
    setSelectedRowData(rowData);
    setSelectedProductName(rowData.productName);
    setSelectedQty(rowData.quantity);
    setSelectedDealtype(rowData.dealType);
    setSelectedScript(rowData.script);
    setSelectedPosition(rowData.position);
    setSelectedPrice1(rowData.price1);
    setSelectedTarget(rowData.target);
    setSelectedStoploss(rowData.stopLoss);
    setSelectedStatus(rowData.statusValue);
    SetSelectedDescription(rowData.description);
    SetSelectedCreatedBy(rowData.created_by);
    togglePopup();
  };

  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleImgPopup = (rowData) => {
    // console.log(rowData);
    setSelectedRowData(rowData);
    setSelectedImg(rowData.uploadFile);
    imgtogglePopup(); // Make sure imgtogglePopup is defined and working as expected
  };
  // ____________________________________________________________________________________________
  // useEffect(() => {
  useEffect(() => {
    const profilesDataFetcher = async () => {
      try {
        const response3 = await fetch(`${api_url}/getprofiles`);
        if (!response3.ok) {
          throw new Error(`HTTP error! Status:${response3.status}`);
        }
        const resData3 = await response3.json();
        const UserProfileData = resData3.filter(
          (profile) => profile.profileName === sessionStorage.userProfile
        );
        setView(UserProfileData[0].status.liveCall.view);
        setUpdate(UserProfileData[0].status.liveCall.edit);
      } catch (error) {
        console.error("Error fetching profiles data:", error);
      }
    };

    profilesDataFetcher();
  }, []);

  useEffect(() => {
    fetch(`${api_url}/getcalls`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setCallsData(data));
  }, [update]);

  const [pdfPopupOpen, setPdfPopupOpen] = useState(false);
  const [pdfPopupToggle, setPdfPopupToggle] = useState(false);
  const [callId, setCallId] = useState("");
  const [uploadPdf, setUploadPdf] = useState();

  const handlepdfPopup = (data) => {
    setPdfPopupOpen(true);
    setCallId(data._id);
  };

  const handlePdfModalToggle = () => {
    setPdfPopupToggle(!pdfPopupToggle);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setUploadPdf(selectedFile);
    }
  };

  const addPdf = async (e) => {
  
    e.preventDefault();
    try{

      const url12 = `${api_url}/updatecall/${callId}`;
  
      const formData = new FormData();
      formData.append("callPdf", uploadPdf);
  
      const response = await fetch(url12, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
  
      const responseData = await response.json();

      if(responseData.success===true){
        setPdfPopupOpen(false)
      } else{
        setPdfPopupOpen(false)
        alert("pdf not added")
      }
    } catch(error){
      console.log("error",error)
    }

  };

 
  const downloadPdf = async () => {
    
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "productName", //access nested data with dot notation
        header: "Product Name",
        size: 20,
      },
      {
        accessorKey: "quantity", //normal accessorKey
        header: "Quantity",
        size: 20,
      },
      {
        accessorKey: "dealType",
        header: "Type",
        size: 20,
      },
      {
        accessorKey: "script",
        header: "Script",
        size: 20,
      },
      {
        accessorKey: "position",
        header: "Position",
        size: 20,
      },
      {
        accessorKey: "price1",
        header: "Entry Price",
        size: 20,
      },
      {
        accessorKey: "target",
        header: "Target",
        size: 20,
      },
      {
        accessorKey: "stopLoss",
        header: "Stoploss",
        size: 20,
      },
      {
        accessorKey: "created_by",
        header: "Created By",
        size: 20,
      },
      {
        accessorKey: "createdAt",
        header: "createdAt",
        size: 40,
        accessorFn: (row) => getDateFormat(row.createdAt),
      },
      {
        accessorKey: "updated_by",
        header: "Updated By",
        size: 20,
      },
      {
        accessorKey: "statusValue",
        header: "Status",
        size: 20,
        accessorFn: (row) => {
          return (
            <div
              style={{
                color: getStatusValueColor(row.statusValue, row.pnl),
                fontWeight: 600,
              }}
            >
              {row.statusValue}
            </div>
          );
        },
      },
      {
        accessorKey: "pnl",
        header: "P&L",
        size: 20,
      },
      {
        accessorKey: "img",
        header: "Img",
        size: 20,
        accessorFn: (rowData) => (
          <button
            className="edit-button"
            onClick={() => handleImgPopup(rowData)}
          >
            Img
          </button>
        ),
      },
      {
        accessorKey: "Action",
        header: "Action",
        size: 20,
        // You can create a custom function to render an edit button
        accessorFn: (rowData) => (
          <button
            className="edit-button"
            onClick={() =>
              update
                ? handleEditClick(rowData)
                : alert("You dont have acces to this, Contact Admin!!")
            }
          >
            Action
          </button>
        ),
      },
      {
        accessorKey: "uploadPdf",
        header: "Upload PDF",
        size: 20,
        accessorFn: (rowData) => (
          <button
            className="edit-button"
            onClick={() => handlepdfPopup(rowData)}
          >
            Upload PDF
          </button>
        ),
      },
      {
        accessorKey: "downloadPdf",
        header: "Download PDF",
        size: 20,
        accessorFn: (rowData) => (
          
          rowData.uploadPdf !== "" && <a href={`${api_url}/callPdf/${rowData.uploadPdf}`} download> Download PDF </a>
          
          
          // <button className="edit-button" onClick={() => downloadPdf(rowData)}>
          //   Download PDF
          // </button>
        ),
      },
    ],
    [callsData]
  );

  const table = useMaterialReactTable({
    columns,
    data: callsData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { e: false },
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
  });

  return (
    <div>
      <Home />
      {view && (
        <div>
          <div className="table-button-container">
            <div className="user-table-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
          <Popup
            isOpen={isPopupOpen}
            toggle={togglePopup}
            data={selectedRowData}
            onClose={togglePopup}
            productNameValue={SelectedProductName}
            quantityValue={selectedQty}
            dealTypeValue={selectedDealtype}
            scriptValue={selectedScript}
            positionValue={selectedPosition}
            price1Value={selectedPrice1}
            targetValue={selectedTarget}
            stoplossValue={selectedStoploss}
            newStatusValue={selectedStatus}
            descriptionValue={selectedDescription}
            adminName={selectedCreatedBy}
          />
          <ImgPopUp
            isOpenImg={imgPopupOpen}
            toggleImg={imgtogglePopup}
            onCloseImg={imgtogglePopup}
            imgData={selectedRowData}
            imgValue={selectedImg}
          />

          <Modal isOpen={pdfPopupOpen} toggle={handlePdfModalToggle}>
            <Form onSubmit={addPdf}>
              <ModalHeader> Upload PDF</ModalHeader>
              <ModalBody>
                <Input
                  id="uploadFile"
                  name="uploadFile"
                  type="file"
                  required="true"
                  onChange={handleFileChange}
                />
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-success" type="submit">
                  Save
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => setPdfPopupOpen(false)}
                >
                  Close
                </button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      )}
      {!view && (
        <div className="user-table-container loading-gif">
          <img src="/loading.gif" alt="loading"></img>
        </div>
      )}
    </div>
  );
};

export default LiveCall;
