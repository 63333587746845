import { useContext, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Home from "../Home/Home";
import { useNavigate } from "react-router-dom";

const api_url = process.env.REACT_APP_API_URL;

const SuggestionList = () => {
  const navigate = useNavigate();

  const [view, setView] = useState(false);

  const [suggestionsData, setSuggestionsData] = useState([]);

  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // _____________________clients Data _______________________
      const suggestionsDataFethcer = async () => {
        try {
          const response1 = await fetch(`${api_url}/showQuery`);
          if (!response1.ok) {
            throw new Error(`HTTP eror! Status:${response1.status}`);
          }
          const resData = await response1.json();
          setSuggestionsData(resData);
        } catch (error) {
          console.error("Error fetching data sugestions", error);
        }
      };
      // _____________________ Profiles Data _______________________
      const profilesDataFetcher = async () => {
        try {
          const response3 = await fetch(`${api_url}/getprofiles`);
          if (!response3.ok) {
            throw new Error(`HTTP error! Status:${response3.status}`);
          }
          const resData3 = await response3.json();
          const UserProfileData = resData3.filter(
            (profile) => profile.profileName === sessionStorage.userProfile
          );
          setProfileData(UserProfileData);
          setView(UserProfileData[0].status.suggestion.view);
        } catch (error) {
          console.error("Error fetching profiles data:", error);
        }
      };
      suggestionsDataFethcer();
      profilesDataFetcher();
    };
    fetchData();
  }, []);

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "customerName", //access nested data with dot notation
        header: "Customer Name",
      },
      {
        accessorKey: "queryDescription",
        header: "Query Description",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        accessorFn: (row) => getDateFormat(row.createdAt),
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        accessorFn: (row) => getDateFormat(row.updatedAt),
      },
    ],
    [profileData, suggestionsData]
  );

  const table = useMaterialReactTable({
    columns,
    data: suggestionsData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { e: false },
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  });

  return (
    <div>
      <Home />
      {view && (
        <div>
          <div className="table-button-container">
            <div className="user-table-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
        </div>
      )}
      {!view && (
        <div className="user-table-container loading-gif">
          <img src="/loading.gif" alt="loading"></img>
        </div>
      )}
    </div>
  );
};

export default SuggestionList;
