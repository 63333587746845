import React, { useEffect, useState } from "react";
import Home from "../Home/Home";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../LoginPage/GlobalContext";
import "../dataTable.css";
const api_url = process.env.REACT_APP_API_URL;

const EditSmtpTable = () => {
  const { emailSmtpDataId, setEmailSmtpDataId } = useGlobalContext();

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [emailSmtpDetails, setEmailSmtpDetails] = useState({});

  // const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     console.log("name", name, value)
  //     setComplaintRowDetails((prevData) => ({
  //         ...prevData,
  //         [name]: value,
  //     }));
  // };

  // ___________________________________________________user Authenticatin____________________
  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  // ____________________________________________________________________________________________

  const handleGoBack = () => {
    navigate("/showEmailSmtp");
  };

  const [adminName, setAdminName] = useState(() =>
    sessionStorage.getItem("displayName")
  );

  // useEffect(()=>{
  //     setAdminName(() => sessionStorage.getItem("displayName"));
  // },[])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //   const payload = {
      //     ReceivedFrom: complaintRowDetails.ReceivedFrom,
      //     CarriedFromPrevMonth: complaintRowDetails.CarriedFromPrevMonth,
      //     Received: complaintRowDetails.Received,
      //     Resolved: complaintRowDetails.CarriedFromPrevMonth,
      //     Pending: complaintRowDetails.CarriedFromPrevMonth,
      //     // createdBy: complaintRowDetails.createdBy,
      //     updatedBy: complaintRowDetails.updatedBy,
      //   };
      const data = new FormData(e.currentTarget);
      const payload = {
        port: data.get("port"),
        host: data.get("host"),
        security: data.get("security"),
        username: data.get("username"),
        from_email: data.get("from_email"),
        password: data.get("password"),
        createdBy: emailSmtpDetails.createdBy,
        updatedBy: adminName,
      };

      // console.log(payload, JSON.stringify(payload));

      const response = await fetch(
        `${api_url}/updateEmail/${emailSmtpDataId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData) {
        setMessage("Data submitted successfully");
        navigate("/showEmailSmtp");
      }
    } catch (error) {
      setMessage("Failed to submit Data");
      console.error("Error", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${api_url}/showEmailSmptbyId/${emailSmtpDataId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      //   console.log("result",result);
      setEmailSmtpDetails(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Home />
      <div className="form-container">
        <div className="form-align">
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword"> Port </Label>
                  <Input
                    placeholder=" Enter here"
                    type="text"
                    name="port"
                    required="true"
                    defaultValue={emailSmtpDetails.port}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword">Host</Label>
                  <Input
                    placeholder=" Enter here"
                    type="text"
                    name="host"
                    required="true"
                    max={100}
                    defaultValue={emailSmtpDetails.host}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword"> Security </Label>
                  <Input
                    placeholder=""
                    type="select"
                    name="security"
                    required="true"
                    defaultValue={emailSmtpDetails.security}
                  >
                    <option selected disabled value="">
                      --Select--
                    </option>
                    <option selected value={false}>
                      False
                    </option>
                    <option value={true}>True</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword">User Name</Label>
                  <Input
                    placeholder=" Enter here"
                    type="text"
                    name="username"
                    required="true"
                    defaultValue={emailSmtpDetails.username}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword">From Email</Label>
                  <Input
                    placeholder=" Enter here"
                    type="email"
                    name="from_email"
                    required="true"
                    max={100}
                    defaultValue={emailSmtpDetails.from_email}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword">Password</Label>
                  <Input
                    placeholder=" Enter here"
                    type="password"
                    name="password"
                    required="true"
                    max={100}
                    defaultValue={emailSmtpDetails.password}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword"> Created By </Label>
                  <Input
                    placeholder=" Enter here"
                    type="text"
                    name="createdBy"
                    required="true"
                    Value={emailSmtpDetails.createdBy}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword"> Updated By </Label>
                  <Input
                    placeholder=" Enter here"
                    type="text"
                    name="updatedBy"
                    required="true"
                    disabled
                    value={adminName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}></Col>
              <Col md={2} className="d-flex justify-content-center">
                <button
                  className="btn btn-success"
                  style={{
                    backgroundColor: "#259D90",
                    border: "1px solid lightgray",
                  }}
                  onClick={handleGoBack}
                >
                  Go Back
                </button>
                <br />
              </Col>
              <Col md={2} className="d-flex justify-content-center">
                <button
                  className="btn btn-success"
                  type="submit"
                  style={{
                    backgroundColor: "#259D90",
                    border: "1px solid lightgray",
                  }}
                >
                  Submit
                </button>
                <br />
              </Col>
            </Row>
            <Col md={4}>
              <p>{message}</p>
            </Col>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditSmtpTable;
