import { useContext, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Home from "../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import { ClientContext } from "./clientContext.jsx/ClientContext";

const api_url = process.env.REACT_APP_API_URL;

const AllClients = () => {
  const navigate = useNavigate();

  const { allClientsData, setClientForEdit } = useContext(ClientContext);
  const { profilesData } = useContext(ClientContext);

  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [create, setCreate] = useState(false);

  const handleEditclient = async (data) => {
    const selectedClient = await allClientsData.filter(
      (client) => client._id === data._id
    );
    await setClientForEdit(selectedClient[0]);
    navigate("/editclient");
  };

  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  useEffect(() => {
    const profilesDataFetcher = async () => {
      try {
        const response3 = await fetch(`${api_url}/getprofiles`);
        if (!response3.ok) {
          throw new Error(`HTTP error! Status:${response3.status}`);
        }
        const resData3 = await response3.json();
        const UserProfileData = resData3.filter(
          (profile) => profile.profileName === sessionStorage.userProfile
        );
        setView(UserProfileData[0].status.client.view);
        setUpdate(UserProfileData[0].status.client.edit);
        setCreate(UserProfileData[0].status.client.create);
      } catch (error) {
        console.error("Error fetching profiles data:", error);
      }
    };

    profilesDataFetcher();
  }, [profilesData]);

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // useEffect(() => {
  //   fetch(`${api_url}/showClientComplaintRow`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setClientComplaintData(data.newClientComplaintRow);
  //       console.log("Data received from the server:", data);
  //     })
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, [update]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "clientId", 
        header: "Client ID",
        size: 10,
      },
      {
        accessorKey: "userName", 
        header: "User Name",
        size: 10,
      },
      {
        accessorKey: "productName", 
        header: "Product Name",
        size: 30,
        accessorFn: (row) => row.products[0].productName,
      },
      {
        accessorKey: "fromDate",
        header: "Start Date",
        size: 10,
        accessorFn: (row) => getDateFormat(row.products[0].fromDate),
      },
      {
        accessorKey: "toDate",
        header: "End Date",
        size: 10,
        accessorFn: (row) => getDateFormat(row.products[0].toDate),
      },
      {
        accessorKey: "serviceStatus",
        header: "Service status",
        size: 10,
        accessorFn: (row) => row.products[0].serviceStatus,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 20,
        accessorFn: (row) => getDateFormat(row.createdAt),
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        size: 10,
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        size: 20,
        accessorFn: (row) => getDateFormat(row.updatedAt),
      },
      {
        accessorKey: "updatedBy",
        header: "Updated By",
        size: 10,
      },
      {
        accessorKey: "productPrice",
        header: "Price",
        size: 10,
        accessorFn: (row) => row.products[0].productPrice,
      },
      {
        accessorKey: "Action",
        header: "Action",
        size: 5,
        // You can create a custom function to render an edit button
        accessorFn: (rowData) => (
          <button
            className="edit-button"
            onClick={() =>
              update
                ? handleEditclient(rowData)
                : alert("You dont have access to this, Contact Admin!!")
            }
          >
            Edit
          </button>
        ),
      },
    ],
    [update]
  );

  const table = useMaterialReactTable({
    columns,
    data: allClientsData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { e: false },
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
  
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  });

  return (
    <div>
      <Home />
      {view && (
        <div>
          <div className="table-button-container">
            {create && (
              <div className="niveshartha-btn-container">
                <Link to="/addclientlist">
                  <button className="niveshartha_btn_add">Add Client</button>
                </Link>
              </div>
            )}
            <div className="user-table-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
        </div>
      )}
      {!view && (
        <div className="user-table-container loading-gif">
          <img src="/loading.gif" alt="loading"></img>
        </div>
      )}
    </div>
  );
};

export default AllClients;
