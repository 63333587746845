import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Home from "../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
const api_url = process.env.REACT_APP_API_URL;
//nested data is ok, see accessorKeys in ColumnDef below

const Review = () => {
  const [reviewData, setReviewData] = useState([]);

  // const { reviewsData } = useContext(ClientContext);
  // const tableRef = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [create, setCreate] = useState(false);
  const [selectedApproval, setSelectedApproval] = useState();
  const [currentReviewId, setCurrentReviewId] = useState();
  const [profileData, setProfileData] = useState([]);
  const [rData, setRdata] = useState([]);

  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const togglePopup = () => setPopupOpen(!isPopupOpen);
  const handleEditClick = (rowData) => {
    // console.log(rowData);
    setSelectedApproval(rowData.approvalValue);
    setCurrentReviewId(rowData._id);
    togglePopup();
  };

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
    profilesDataFetcher();
  }, []);
  // ____________________________________________________________________________________________
  // useEffect(() => {
  const profilesDataFetcher = async () => {
    try {
      const response3 = await fetch(`${api_url}/getprofiles`);
      if (!response3.ok) {
        throw new Error(`HTTP error! Status:${response3.status}`);
      }
      const resData3 = await response3.json();
      const UserProfileData = resData3.filter(
        (profile) => profile.profileName === sessionStorage.userProfile
      );
      setProfileData(UserProfileData);
      setView(UserProfileData[0].status.review.view);
      setUpdate(UserProfileData[0].status.review.edit);
      setCreate(UserProfileData[0].status.review.create);
    } catch (error) {
      console.error("Error fetching profiles data:", error);
    }
  };

  useEffect(() => {
    fetch(`${api_url}/showreviews`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setReviewData(data));
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "customerName", //access nested data with dot notation
        header: "Name",
        // size: 150,
      },
      {
        accessorKey: "reviewDescription", //normal accessorKey
        header: "Review",
        // size: 350,
      },
      {
        accessorKey: "approvalValue",
        header: "Approval",
        // size: 50,
      },
      {
        accessorKey: "createdAt",
        header: "CreatedAt",
        // size: 50,
        accessorFn: (row) => getDateFormat(row.createdAt),
        // Cell: ({ rowData }) => (TimestampConversion(rowData.createdAt))
      },
      {
        accessorKey: "Action",
        header: "Action",
        // size: 50,
        // You can create a custom function to render an edit button
        accessorFn: (rowData) => (
          <button
            className="niveshartha_btn_add"
            onClick={() =>
              update
                ? handleEditClick(rowData)
                : alert("You Don't have the access to edit")
            }
          >
            Edit
          </button>
        ),
      },
    ],
    [update, profileData]
  );

  const table = useMaterialReactTable({
    columns,
    data: reviewData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { e: false },
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
  });

  const HandleStatus = (e) => {
    const value = e.target.value;
    setStatus(value);
    // console.log(value)
  };
  useEffect(() => {
    // console.log(status)
  }, [status]);

  const handlePopupConfirm = async () => {
    try {
      //  data submission logic here
      const data = new FormData(document.querySelector("#actionForm"));
      let url = `${api_url}/updateReview/${currentReviewId}`;

      const payload = {
        approvalValue: data.get("approvalValue"),
      };
      // console.log(payload)

      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // console.log('Product added successfully!!');
      if (responseData) {
        fetch(`${api_url}/showreviews`)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setReviewData(data);
          })
          .catch((error) => console.error("Error fetching data:", error));

        // Close the popup
        togglePopup();
      }
      // navigate('/reviews')
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <div>
      <Home />
      {view && (
        <div>
          <div className="table-button-container">
            {create && (
              <div className="niveshartha-btn-container">
                <Link to="/addreview">
                  <button className="niveshartha_btn_add">Add Review</button>
                </Link>
              </div>
            )}
            <div className="user-table-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
          <Modal isOpen={isPopupOpen} toggle={togglePopup}>
            <ModalHeader>Review Action</ModalHeader>
            <ModalBody>
              <Form id="actionForm">
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="exampleCity">Action</Label>
                      <Input
                        id="exampleSelect"
                        name="approvalValue"
                        type="select"
                        onChange={HandleStatus}
                      >
                        <option selected disabled value="">
                          --Select--
                        </option>
                        <option>Approved</option>
                        <option>Not Approved</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-success" onClick={handlePopupConfirm}>
                Submit
              </button>
              <button className="btn btn-danger" onClick={togglePopup}>
                Close
              </button>
            </ModalFooter>
          </Modal>
        </div>
      )}
      {!view && (
        <div className="user-table-container loading-gif">
          <img src="/loading.gif" alt="loading"></img>
        </div>
      )}
    </div>
  );
};

export default Review;
