import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import { ClientContext } from "./clientContext.jsx/ClientContext";
import Home from "../Home/Home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import { cleanData } from "jquery";
const api_url = process.env.REACT_APP_API_URL;

const AddClientList = () => {
  const [clientData, setClientData] = useState({
    userName: "",
    password: "",
    name: "",
    email: "",
    clientPhoneNumber: "",
    clientId:"",
    products: [
      {
        productName: "",
        productPrice: 0,
        productDuration: "",
        fromDate: new Date(),
        toDate: new Date(),
        paidAmount: 0,
        dueAmount: 0,
        serviceStatus: "",
        kycStatus: "",
        saStatus: "",
      },
    ],
    createdBy: "",
    updatedBy: "",
  });
    // console.log("✌️clientData --->", clientData);

  const [adminName, setAdminName] = useState(() =>
    sessionStorage.getItem("displayName")
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const lowerCaseEmail = clientData.email.toLowerCase();
    try {
      
      const payload = {
        userName: clientData.userName,
        password: clientData.password,
        name: clientData.name,
        email: lowerCaseEmail,
        clientPhoneNumber: clientData.clientPhoneNumber,
        clientId:+clientData.clientId,
        createdBy: adminName,
        updatedBy: clientData.updatedBy,
        products: clientData.products.map((product, index) => ({
          productName: product.productName,
          productPrice: product.productPrice,
          productDuration: product.productDuration,
          fromDate: new Date(product.fromDate),
          toDate: new Date(product.toDate),
          paidAmount: product.paidAmount,
          serviceEndDate: product.toDate,
          dueAmount: +product.dueAmount,
          serviceStatus: product.serviceStatus,
          kycStatus: product.kycStatus,
          saStatus: product.saStatus,
        })),
      };
      // console.log("✌️payload --->", payload);

      const response = await fetch(`${api_url}/addclientlist`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // ____________________Data population can be added________________
      // setAllClientsData({ ...allClientsData });
      if (responseData) {
        navigate("/clientlist");
        setMessage("Data submitted successfully");
      }
    } catch (error) {
      setMessage("Failed to submit Data");
      console.error("Error", error);
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    setClientData((prevReport) => {
      const newData = [...prevReport.products];
      newData[index] = {
        ...newData[index],
        [fieldName]: value,
      };
      return {
        ...prevReport,
        products: newData,
      };
    });
  };

  const [selectedProduct_Price, setSelectedProduct_Price] = useState(0);
  const [selectedProduct_Duration, setSelectedProduct_Duration] = useState(0);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handlegoback = () => {
    navigate("/clientlist");
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...clientData.products];


    if (field === "fromDate" ) {
      const inputDate = new Date(value);

      const serviceEndDate = new Date(
        inputDate.getTime() + selectedProduct_Duration * 24 * 60 * 60 * 1000
      ); 
      const formattedServiceEndDate = serviceEndDate.toString();

      // console.log(formattedServiceEndDate);
      updatedProducts[index]["fromDate"] = new Date(value);
      updatedProducts[index]["toDate"] = formattedServiceEndDate
      
    } else if (field === "toDate" ) {
        updatedProducts[index]["toDate"] = new Date(value);
    } else if(field==="paidAmount"){
      const dueAmount = selectedProduct_Price-value
      updatedProducts[index]["paidAmount"] = value;
      updatedProducts[index]["dueAmount"] = dueAmount;
    }
    updatedProducts[index][field] = value;

    setClientData({
      ...clientData,
      products: updatedProducts,
    });
  };

  const handleChoseProduct = (index, event) => {
    // console.log('✌️index --->', index);

    const selectedProduct =
      event.target.options[event.target.selectedIndex].dataset.myDataAttribute;
    const selectedProductParts = selectedProduct.split("-");
    const selectedProduct_name = selectedProductParts[0];
    const selectedProduct_catergory = selectedProductParts[1];
    // setSelectedProduct_Name(
    //   selectedProduct_name + "-" + selectedProduct_catergory
    // );
    const selectedProduct_duration = parseInt(selectedProductParts[2]);

    const selectedProductData = productData.find(
      (product) => product.productName === selectedProduct_name
    );

    if (selectedProductData) {
      const selectedProductCaps = selectedProductData.caps.filter(
        (cap) => cap.capCategory === selectedProduct_catergory
      );
      const selectedCap = selectedProductCaps.find(
        (cap) => cap.capDuration === selectedProduct_duration
      );
      // console.log("✌️selectedCap --->", selectedCap);
      if (selectedCap) {
        const selectedPrice = selectedCap.capPrice;
        setSelectedProduct_Price(selectedPrice);
        handleProductChange(index, "productPrice", selectedPrice);
        
        handleProductChange(index, "dueAmount", selectedPrice);
        setSelectedProduct_Duration(selectedProduct_duration);
        handleProductChange(index, "productDuration", selectedCap.capDuration);
      }
    }
  };
  const handleDelete = (index) => {
    const updatedProducts = [...clientData.products];
    updatedProducts.splice(index, 1); // Remove the product at the specified index
    setClientData({
      ...clientData,
      products: updatedProducts,
    });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // const [clientIdValue, setClientIdValue] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow only numbers 0-9
    const regex = /^[0-9]*$/;
    if (inputValue === "" || regex.test(inputValue)) {
      setClientData({ ...clientData, clientId: inputValue })
    }
  };

  const handleAdd = () => {
    setClientData({
      ...clientData,
      products: [
        ...clientData.products,
        {
          productName: "",
          productPrice: 0,
          productDuration: "",
          fromDate: new Date(),
          toDate: new Date(),
          paidAmount: 0,
          dueAmount: 0,
          serviceStatus: "",
          kycStatus: "",
          saStatus: "",
        },
      ],
    });
  };
  const [productData, setProductData] = useState([]);
  const [productOptionData, setProductOptionData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${api_url}/newProductList`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        const newArray = result.flatMap((item) => {
          return item.caps.map((cap) => ({
            name: `${item.productName}-${cap.capCategory}`,
            capPrice: cap.capPrice,
            capDuration: cap.capDuration,
          }));
        });
        setProductOptionData(newArray);
        setProductData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div>
      <Home />
      <div className="form-container">
        <div className="form-align">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3}>
                <h4>App Credentials</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleState">Email</Label>
                  <Input
                    name="email"
                    type="email"
                    required="true"
                    value={clientData.email}
                    onChange={(e) =>
                      setClientData({
                        ...clientData,
                        email: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    required="true"
                    value={clientData.password}
                    onChange={(e) =>
                      setClientData({ ...clientData, password: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>General Information</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>User Name</Label>
                  <Input
                    type="text"
                    name="userName"
                    required="true"
                    value={clientData.userName}
                    onChange={(e) =>
                      setClientData({ ...clientData, userName: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="exampleZip">Mobile Number</Label>
                  <Input
                    name="clientPhoneNumber"
                    type="tel"
                    required={true}
                    maxLength={10}
                    pattern="[0-9]*" // Only allow numeric input
                    value={clientData.clientPhoneNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,10}$/.test(inputValue)) {
                        setClientData({
                          ...clientData,
                          clientPhoneNumber: inputValue,
                        });
                      }
                    }}
                  ></Input>
                </FormGroup>
              </Col>

              <Col md={4}>
              <Label for="exampleZip">Client Id</Label>
              <Input
                    type="text"
                    name="userName"
                    required="true"
                    // value={clientData.clientId}
                    // onChange={(e) =>
                    //   setClientData({ ...clientData, clientId: e.target.value })
                    // }
                    value={clientData.clientId}
                    onChange={handleChange}
                  />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={3}>
                <h4>Product Information</h4>
              </Col>
            </Row>
            {clientData.products.map((product, index) => {
              return (
                <Fragment key={index}>
                  <Row>
                    <Col md={3}>
                      <h5>Product Details</h5>
                    </Col>
                    <Col md={2}>
                      <button
                        type="button"
                        style={{
                          border: "none",
                          padding: "10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "end",
                        }}
                        onClick={() => handleDelete(index)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{
                            color: "red",
                            fontSize: "14px",
                          }}
                        />
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Product Name</Label>
                        <Input
                          type="select"
                          name={`products[${index}].productName`}
                          // value={product.productName}
                          // handleChoseProduct(index, e);
                        //   value={product.productName}
                          onChange={(e) => {
                            handleChoseProduct(index, e);
                            handleInputChange(
                              index,
                              "productName",
                              e.target.value
                            );
                          }}
                        >
                          <option selected disabled value="">
                            --Select--
                          </option>
                          {productOptionData?.map((item) => (
                            <option
                              key={item.name}
                              className="dropdown__option"
                              data-my-data-attribute={
                                item.name +
                                "-" +
                                item.capDuration +
                                "-" +
                                item.capPrice
                              }
                              value={item.name}
                            >
                              {item.name}${item.capDuration}{" "}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleState">Product Price</Label>
                        <Input
                          id="exampleState"
                          name={`products[${index}].productPrice`}
                          type="number"
                          min={0}
                          value={product.productPrice}
                          // readOnly
                          disabled
                          // onChange={(e) => handleChoseProduct(index, e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleCity">Paid Amount</Label>
                        <Input
                          id="exampleSelect"
                          name={`products[${index}].paidAmount`}
                          type="number"
                          min={0}
                          required="true"
                          value={product.paidAmount}
                          onChange={(e) =>
                            handleProductChange(
                              index,
                              "paidAmount",
                              e.target.value
                            )
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleState">Due Amount</Label>
                        <Input
                          id="exampleState"
                          name={`products[${index}].dueAmount`}
                          type="number"
                          min={0}
                          // disabled
                          value={product.dueAmount}
                          //   onChange={(e) => handleChoseProduct(index, e)}
                          onChange={(e) => handleProductChange(index, 'dueAmount', e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleCity">
                          From date{" "}
                          <span style={{ fontSize: "12px", fontWeight: 600 }}>
                            {" "}
                            (DD/MM/YYYY){" "}
                          </span>
                        </Label>
                        <Input
                          id="exampleSelect"
                          name={`products[${index}].fromDate`}
                          type="date"
                          required="true"
                          value={formatDate(product.fromDate)}
                          onChange={(e) =>
                            handleProductChange(
                              index,
                              "fromDate",
                              e.target.value
                            )
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleState">
                          To date{" "}
                          <span style={{ fontSize: "12px", fontWeight: 600 }}>
                            {" "}
                            (DD/MM/YYYY){" "}
                          </span>
                        </Label>
                        <Input
                          id="exampleState"
                          name={`products[${index}].toDate`}
                          type="date"
                          min={0}
                          value={formatDate(product.toDate)}
                          //   disabled
                          onChange={(e) =>
                            handleProductChange(index, "toDate", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Service Status</Label>
                        <Input
                          id="exampleSelect"
                          name={`products[${index}].serviceStatus`}
                          type="select"
                          required="true"
                          value={product.serviceStatus}
                          onChange={(e) =>
                            handleProductChange(
                              index,
                              "serviceStatus",
                              e.target.value
                            )
                          }
                        >
                          <option selected disabled value="">
                            --Select--
                          </option>
                          <option>Active</option>
                          <option>Expired</option>
                          <option>Hold</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">Kyc Status</Label>
                        <Input
                          id="exampleState"
                          name={`products[${index}].kycStatus`}
                          type="select"
                          required="true"
                          value={product.kycStatus}
                          onChange={(e) =>
                            handleProductChange(
                              index,
                              "kycStatus",
                              e.target.value
                            )
                          }
                        >
                          <option value="">--Select--</option>
                          <option selected>Yes</option>
                          <option>No</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">SA Status</Label>
                        <Input
                          id="exampleState"
                          name={`products[${index}].saStatus`}
                          type="select"
                          required="true"
                          value={product.saStatus}
                          onChange={(e) =>
                            handleProductChange(
                              index,
                              "saStatus",
                              e.target.value
                            )
                          }
                        >
                          <option value="">--Select--</option>
                          <option selected>Yes</option>
                          <option>No</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                </Fragment>
              );
            })}
            <br />
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="examplePassword"> Created By </Label>
                  <Input
                    placeholder=" Enter here"
                    type="text"
                    name="createdBy"
                    required="true"
                    value={adminName}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={3}></Col>
              <Col md={2}>
                <button
                  className="btn btn-primary"
                  type="btn"
                  style={{
                    backgroundColor: "#259D90",
                    border: "1px solid lightgray",
                  }}
                  onClick={handlegoback}
                >
                  Go Back
                </button>
              </Col>
              <Col md={2}>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleAdd(clientData.products.length)}
                >
                  Add Product
                </button>
              </Col>
              <Col md={2}>
                {clientData.products.length !== 0 ? (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{
                      backgroundColor: "#259D90",
                      border: "1px solid lightgray",
                    }}
                  >
                    Submit
                  </button>
                ) : (
                  " "
                )}
                <p>{message}</p>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddClientList;
