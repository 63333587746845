import { useContext, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Home from "../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../LoginPage/GlobalContext";

const api_url = process.env.REACT_APP_API_URL;

const Profile = () => {
  const navigate = useNavigate();

  const { profileId, setProfileId } = useGlobalContext();

  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [create, setCreate] = useState(false);

  const [profilesData, setProfilesData] = useState([]);

  const onEditClick = (_id) => {
    setProfileId(_id);
    navigate("/editprofile");
  };

  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const profilesDataFetcher = async () => {
      try {
        const response3 = await fetch(`${api_url}/getprofiles`);
        if (!response3.ok) {
          throw new Error(`HTTP error! Status:${response3.status}`);
        }
        const resData3 = await response3.json();
        const UserProfileData = resData3.filter(
          (profile) => profile.profileName === sessionStorage.userProfile
        );
        // setProfileData(UserProfileData);
        setView(UserProfileData[0].status.profile.view);
        setUpdate(UserProfileData[0].status.profile.edit);
        setCreate(UserProfileData[0].status.profile.create);
      } catch (error) {
        console.error("Error fetching profiles data:", error);
      }
    };
    profilesDataFetcher();
  }, []);

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  useEffect(() => {
    fetch(`${api_url}/getprofiles`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setProfilesData(data);
        // console.log("Data received from the server:", data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "profileName", //access nested data with dot notation
        header: "Profile Name",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        accessorFn: (row) => getDateFormat(row.createdAt),
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        accessorFn: (row) => getDateFormat(row.updatedAt),
      },
      {
        accessorKey: "Action",
        header: "Action",
        // You can create a custom function to render an edit button
        accessorFn: (rowData) => (
          <button
            className="edit-button"
            onClick={() =>
              update
                ? onEditClick(rowData._id)
                : alert("You dont have acces to this, Contact Admin!!")
            }
          >
            Edit
          </button>
        ),
      },
    ],
    [update]
  );

  const table = useMaterialReactTable({
    columns,
    data: profilesData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { e: false },
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  });

  return (
    <div>
      <Home />
      {view && (
        <div>
          <div className="table-button-container">
            {create && (
              <div className="niveshartha-btn-container">
                <Link to="/addprofile">
                  <button className="niveshartha_btn_add">Add Profile</button>
                </Link>
              </div>
            )}
            <div className="user-table-container">
              <MaterialReactTable table={table} />
            </div>
          </div>
        </div>
      )}
      {!view && (
        <div className="user-table-container loading-gif">
          <img
            src="/loading.gif"
            alt="loading"
          ></img>
        </div>
      )}
    </div>
  );
};

export default Profile;
