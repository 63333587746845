import React, { useEffect, useState } from "react";
import Home from "../Home/Home";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";

const api_url = process.env.REACT_APP_API_URL;

const DownloadFactSheet = () => {
  const navigate = useNavigate();

  const [view, setView] = useState(false);

  const [isProfile,setIsProfile] = useState("")

  const profilesDataFetcher = async () => {
    try {
      const response3 = await fetch(`${api_url}/getprofiles`);
      if (!response3.ok) {
        throw new Error(`HTTP error! Status:${response3.status}`);
      }
      const resData3 = await response3.json();

      const UserProfileData = resData3.filter(
        (profile) => profile.profileName === sessionStorage.userProfile
        );
        setIsProfile(UserProfileData[0].profileName)
        setView(UserProfileData[0].status.factSheet.view);
    } catch (error) {
      console.error("Error fetching profiles data:", error);
    }
  };

  const [productReportData, setProductReportData] = useState({
    activeProduct: 0,
    expiredProduct: 0,
  });
  const [individualProductReport, setIndividualReport] = useState([]);
  const getProductReport = async () => {
    fetch(`${api_url}/getProductReport`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log('✌️data --->', data);
        setProductReportData({
          activeProduct: data.result.activeProducts,
          expiredProduct: data.result.expiredProducts,
        });
        setIndividualReport(data.productIndividualReport);
        // console.log("Data received from the server:", data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  useEffect(() => {
    fetch(`${api_url}/getuser`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userObject = data.find(
          (user) => user._id === sessionStorage.userId
        );
        // setIsValidUser(userObject.active);
        if (!userObject.active) {
          navigate("/");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
    profilesDataFetcher();
    getProductReport();
  }, []);

  const [productData, setProductData] = useState([]);
  // console.log('✌️productData --->', productData);
  useEffect(() => {
    const uniqueProductKeys = new Set();
    const fetchData = async () => {
      try {
        const response = await fetch(`${api_url}/newProductList`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        const result2 = await result.flatMap((item) =>
          item.caps.map((cap) => {
            const key = `${item.productName}-${cap.capCategory}`;
            if (!uniqueProductKeys.has(key)) {
              uniqueProductKeys.add(key);
              return {
                value: key,
                label: `${item.productName} - ${cap.capCategory}`,
              };
            }
            return null;
          })
        );
        const finallist = await result2.filter((item) => item !== null);
        console.log("✌️finallist --->", finallist);
        setProductData(finallist);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const productOption = productData.map((item) => {
    return { value: item.value, label: item.label };
  });

  const [selectedProduct, setselectedProduct] = useState({
    value: "",
    label: "",
  });
  // console.log('✌️selectedProduct --->', selectedProduct);

  const [startDate,setStartDate] = useState()
  const [endDate,setEndDate] = useState()

  const downloadXlFactSheet = async () => {
    const productName = selectedProduct.value;

    try {
      const url = `${api_url}/factSheetForDashboard?productName=${productName}`;
  
      const sDate = new Date(startDate)
      const eDate = new Date(endDate)

      const option = {
        headers: {"Content-Type" : "application/json"},
        method : "POST",
        body : JSON.stringify({ startDate : sDate,endDate : eDate})
      }

      const response = await fetch(url,option);

      if (response.ok) {
        console.log("first");
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${selectedProduct.label}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        alert("Failed to download ");
      }
    } catch (error) {}
  };

  return (
    <div>
      {" "}
      <Home />
      {view && (
        <div className="form-container">
          <div className="form-align">
            <div>
              <h3> Download FactSheet </h3>
            </div>
            <Row>
              <Col md={4}>
                <Label> Select Product </Label>
                <Select
                  id="productName"
                  name="productName"
                  maxMenuHeight={150}
                  options={productOption}
                  value={selectedProduct}
                  onChange={(e) => {
                    setselectedProduct(e);
                  }}
                  placeholder="Select..."
                />
              </Col>
              {
                isProfile === "Admin" && <>
                  <Col md={2}>
                  <Label> Start Date : </Label>
                  <Input type="date" value={startDate} onChange={(e)=>setStartDate(e.target.value)} />
              </Col>
              <Col md={2}>
                  <Label> End Date : </Label>
                  <Input type="date" value={endDate} onChange={(e)=>setEndDate(e.target.value)} />
              </Col>
                </>
              }
              
              <Col md={1}></Col>
              <Col md={1}>
              <Label></Label>
                <Button
                  className="btn btn-success"
                  onClick={downloadXlFactSheet}
                >
                  {" "}
                  Download{" "}
                </Button>
              </Col>
            </Row>
            <div style={{ marginTop: "30px" }}>
              <h3> Total Reports </h3>
            </div>
            <Row>
              <Col md={6} lg={3} sm={12}>
                <Card className="dashboard-card my-2 ">
                  <CardHeader className="dashboard-card-header">
                    Total No. of Client Active Product Wise
                  </CardHeader>
                  <CardBody className="dashboard-cardbody">
                    <CardText>{productReportData.activeProduct}</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} lg={3} sm={12}>
                <Card className="dashboard-card my-2 ">
                  <CardHeader className="dashboard-card-header">
                    Total No. of Client Expired Product Wise
                  </CardHeader>
                  <CardBody className="dashboard-cardbody">
                    <CardText>{productReportData.expiredProduct}</CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div style={{ marginTop: "30px" }}>
              <h3> Individual Product Reports </h3>
            </div>

            <Row>
              {individualProductReport &&
                individualProductReport.map((datas, index) => (
                  <Col md={6} lg={3} sm={12}>
                    <Card className="dashboard-card my-2 ">
                      <CardHeader className="dashboard-card-header">
                        {`${datas.productName}`}
                      </CardHeader>
                      <CardBody className="dashboard-cardbody">
                        <CardText>{`Active - ${datas.activeCount}`}</CardText>
                        <CardText>{`Expired - ${datas.expiredCount}`}</CardText>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadFactSheet;
