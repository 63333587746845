import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Home from "../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../LoginPage/GlobalContext";

const api_url = process.env.REACT_APP_API_URL;

const CouponList = () => {
  const navigate = useNavigate();

  const { couponId, setCouponId } = useGlobalContext();

  const [couponData, setCouponData] = useState([]);

  //   const [view, setView] = useState(false);
  //   const [update, setUpdate] = useState(false);
  //   const [create, setCreate] = useState(false);

  //   const [profileData, setProfileData] = useState([]);

  const onEditClick = (_id) => {
    setCouponId(_id);
    navigate("/editCoupon");
  };

  //   useEffect(() => {
  //     fetch(`${api_url}/getuser`)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         const userObject = data.find((user) => user._id === sessionStorage.userId);
  //         // setIsValidUser(userObject.active);
  //         if (!userObject.active) {
  //           navigate('/')
  //         }
  //       })
  //       .catch((error) => console.error("Error fetching data:", error));
  //     profilesDataFetcher();
  //   }, []);

  //   const profilesDataFetcher = async () => {
  //     try {
  //       const response3 = await fetch(`${api_url}/getprofiles`);
  //       if (!response3.ok) {
  //         throw new Error(`HTTP error! Status:${response3.status}`);
  //       }
  //       const resData3 = await response3.json();
  //       const UserProfileData = resData3.filter(
  //         (profile) => profile.profileName === sessionStorage.userProfile
  //       );
  //       setProfileData(UserProfileData);
  //       setView(UserProfileData[0].status.complaintTable.view);
  //       setUpdate(UserProfileData[0].status.complaintTable.edit);
  //       setCreate(UserProfileData[0].status.complaintTable.create);
  //     } catch (error) {
  //       console.error("Error fetching profiles data:", error);
  //     }
  //   };

  const getDateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  useEffect(() => {
    fetch(`${api_url}/showCoupon`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCouponData(data);
        // console.log("Data received from the server:", data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "couponName", //access nested data with dot notation
        header: "Coupon Name",
        size: 10,
      },
      {
        accessorKey: "discount", //normal accessorKey
        header: "Discount",
        size: 200,
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        size: 10,
      },
      {
        accessorKey: "updatedBy",
        header: "Updated By",
        size: 10,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 15,
        accessorFn: (row) => getDateFormat(row.createdAt),
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        size: 15,
        accessorFn: (row) => getDateFormat(row.createdAt),
      },
      {
        accessorKey: "Action",
        header: "Action",
        size: 10,
        // You can create a custom function to render an edit button
        accessorFn: (rowData) => (
          <button
            className="edit-button"
            onClick={() => onEditClick(rowData._id)}
            // onClick={() => update ? onEditClick(rowData._id) : alert("You dont have acces to this, Contact Admin!!")}
          >
            Edit
          </button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: couponData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { e: false },
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    paginationDisplayMode: "pages",
    muiTablePaperProps: {
      elevation: 0,
    },
    globalFilterFn: "contains",
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  });

  return (
    <div>
      <Home />
      {/* {view && (
        <div> */}
      <div className="table-button-container">
        {/* {create && ( */}
        <div className="niveshartha-btn-container">
          <Link to="/addCoupon">
            <button className="niveshartha_btn_add">Add Coupon</button>
          </Link>
        </div>
        {/* )} */}
        <div className="user-table-container">
          <MaterialReactTable table={table} />
        </div>
      </div>
      {/* </div>
      )} */}
      {/* {!view && ( */}
      <div className="user-table-container loading-gif">
        <img src="/loading.gif" alt="loading"></img>
      </div>
      {/* )} */}
    </div>
  );
};

export default CouponList;
